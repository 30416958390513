import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const pageCialdini = (container) => {
    $('.cialdini-menu__item').on('click',function (e) {
        e.preventDefault();

        let id = $(this).data('id');
        let target = $('.cialdini-item[data-id="' + id + '"]');
        let headerHeight = $('.header').outerHeight();

        $('html, body').stop().animate({
            'scrollTop': target.offset().top - headerHeight,
        }, 1200, 'swing', function () {
            // window.location.hash = target;
        });
    });


    let techniques = container.querySelectorAll('.cialdini-item');

    techniques.forEach(el => {
        let image = el.querySelector('.cialdini-item__content');
        let content = el.querySelector('.cialdini-item__type');
        const tl = gsap.timeline();

        tl.to(image, {
            x: '0',
            autoAlpha: 1,
            scrollTrigger: {
                trigger: el,
                start: '10% 80%',
                end: '90% 80%',
                scrub: true,
                markers: false,
            }}, 0);

        tl.to(content, {
            x: '0',
            autoAlpha: 1,
            scrollTrigger: {
                trigger: el,
                start: '0 85%',
                end: '90% 85%',
                scrub: true,
                markers: false,
            }}, 0);
    });

    const popups = container.querySelectorAll('.cialdini-item--popup');

    popups.forEach(el => {
        const popup = el.querySelector('.cialdini-popup');
        const close = el.querySelector('.cialdini-popup__close');
        const tl = gsap.timeline();

        if (popup) {
            close.addEventListener('click', (e) => {
                e.preventDefault();
                popup.parentElement.classList.remove('is-active');
            });

            tl.to(popup, {
                scrollTrigger: {
                    trigger: el,
                    start: '10% 80%',
                    end: '90% 80%',
                    scrub: true,
                    markers: false,
                    toggleClass: 'is-active',
                    once: true,
                }}, 0);
        }
    });

}

export default pageCialdini;

