import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const pageHome = (container) => {

    let banner = container.querySelector('.front__banner__black');
    let bannerTitle = container.querySelectorAll('.front__banner__title__row');
    let bannerSubtitle = container.querySelectorAll('.front__banner__subtitle__row');
    let playBtn = container.querySelector('.front__banner__play__outer');
    let playBtnText = container.querySelector('.front__banner__play');

    let tl = gsap.timeline();
    tl
        .to(banner, {autoAlpha: 0.6}, '0')
        .to(bannerSubtitle, {autoAlpha: 1, y: 0, stagger: 0.22})
        .to(bannerTitle, {autoAlpha: 1, y: 0, stagger: 0.22}, '>-0.2')
        .to(playBtn, {pointerEvents: 'auto'})


    let scrollLine = container.querySelector('.front__scroll__outer');
    let playOuter = container.querySelector('.front__banner__play__outer');
    let videoLong = container.querySelector('.front__video__long');
    let videoLongSrc = container.querySelector('.video__long__src');
    let videoBlack = container.querySelector('.front__banner__black');
    let videoPlayToggleButton = container.querySelector('.front__video__short__button');
    const video = container.querySelector('.front__video__short video');

    let body = document.body;

    playBtn.addEventListener('click', showVideo);
    videoPlayToggleButton.addEventListener('click', videoPlayToggle);

    function videoPlayToggle() {
        if (video.paused) {
            $(this).removeClass('is-paused');
            video.play();
            playBtnText.style.animationPlayState = 'running';
        } else {
            $(this).addClass('is-paused');
            video.pause();
            playBtnText.style.animationPlayState = 'paused';
        }
    }

    function isTouchDevice() {
        return (('ontouchstart' in window) ||
            (window.navigator.maxTouchPoints > 0) ||
            (window.navigator.msMaxTouchPoints > 0));
    }

    function showVideo() {
        // Todo: remove pause button when video is playing


        videoPlayToggleButton.classList.remove('is-paused');
        videoPlayToggleButton.classList.add('is-disabled');
        video.play();
        playBtnText.style.animationPlayState = 'running';
        body.classList.add('body-overflow-hidden');
        if (window.navigator.userAgent.indexOf('Linux') != -1 || window.navigator.userAgent.indexOf('Windows') != -1) {
            body.classList.add('body-padding-right');
        }

        const tl = gsap.timeline();

        tl
            .to(bannerTitle, {autoAlpha: 0, y: -20, stagger: 0.22})
            .to(bannerSubtitle, {autoAlpha: 0, y: 30, stagger: 0.22}, '>-0.2')
            .to(scrollLine, {autoAlpha: 0, y: 30}, '<')
            .to(playOuter, {autoAlpha: 0, scale: 1.4}, '<')

        tl.eventCallback('onComplete', function () {
            videoLongSrc.muted = false;

            if (window.navigator.userAgent.indexOf('iPad') != -1 || window.navigator.userAgent.indexOf('iPhone') != -1) {
                videoLongSrc.muted = true;
            }

            if (window.navigator.userAgent.indexOf('Macintosh') != -1 && isTouchDevice() !== false) {
                videoLongSrc.muted = true;
            }

            videoLongSrc.play();

            const tl = gsap.timeline();
            tl
                .to(videoLong, {autoAlpha: 1})
                .to(videoBlack, {autoAlpha: 0}, '<');
        });

        if (videoLongSrc) {
            videoLongSrc.addEventListener('ended', videoEnd, false);
        }

    }

    if (videoLongSrc) {
        videoLongSrc.addEventListener('click', videoPause)
    }

    function videoPause() {
        body.classList.remove('body-overflow-hidden');
        body.classList.remove('body-padding-right');
        videoLongSrc.pause();

        const tl = gsap.timeline();
        tl
            .to(videoBlack, {autoAlpha: 0.6}, '<')
            .to(bannerSubtitle, {autoAlpha: 1, y: 0, stagger: 0.22})
            .to(bannerTitle, {autoAlpha: 1, y: 0, stagger: 0.22}, '>-0.2')
            .to(playOuter, {autoAlpha: 1, scale: 1}, '<')
            .to(scrollLine, {autoAlpha: 1, y: 0}, '<')
    }

    function videoEnd() {
        body.classList.remove('body-overflow-hidden');
        body.classList.remove('body-padding-right');
        videoLongSrc.pause();
        videoPlayToggleButton.classList.remove('is-disabled');

        const tl = gsap.timeline();
        tl
            .to(videoLong, {autoAlpha: 0})
            .to(videoBlack, {autoAlpha: 0.6}, '<')
            .to(bannerSubtitle, {autoAlpha: 1, y: 0, stagger: 0.22})
            .to(bannerTitle, {autoAlpha: 1, y: 0, stagger: 0.22}, '>-0.2')
            .to(playOuter, {autoAlpha: 1, scale: 1}, '<')
            .to(scrollLine, {autoAlpha: 1, y: 0}, '<')

        tl.eventCallback('onComplete', function () {
            setTimeout(function () {
                videoLongSrc.currentTime = 0;
            }, 1000)
        })
    }


    let frontProjects = container.querySelector('.front__projects');
    let frontProjectsMid = container.querySelector('.project__page__items--mid');
    let frontProjectsRight = container.querySelector('.project__page__items--right');

    const windowWidth = $(window).width();

    let projectMargin = ''
    if (windowWidth > 0 && windowWidth <= 767) {
        projectMargin = '85';
    } else if (windowWidth > 767 && windowWidth <= 1199) {
        projectMargin = '85';
    } else if (windowWidth > 1199) {
        projectMargin = '120';
    }

    const tlProjects = gsap.timeline();
    tlProjects.to(frontProjectsMid, {
        y: projectMargin + 'px', scrollTrigger: {
            trigger: frontProjects,
            start: 'top 40%',
            end: 'bottom 40%',
            scrub: true,
            markers: false,
        },
    }, 0);

    tlProjects.to(frontProjectsRight, {
        y: projectMargin * 2 + 'px', scrollTrigger: {
            trigger: frontProjects,
            start: 'top 40%',
            end: 'bottom 40%',
            scrub: true,
            markers: false,
        },
    }, 0);


    if (windowWidth <= 767) {
        let projectItems = container.querySelectorAll('.project__content')
        let projectSlider = document.createElement('div');
        let projectContainer = container.querySelector('.front__projects__inner');
        projectSlider.setAttribute('class', 'front__projecten__slider');
        for (let i = 0; i < projectItems.length; i++) {
            projectSlider.appendChild(projectItems[i]);
        }

        // Append slider to html
        projectContainer.appendChild(projectSlider);

        // Remove old html
        let removeOldProjects = container.querySelectorAll('.project__page__items')
        for (let i = 0; i < removeOldProjects.length; i++) {
            removeOldProjects[i].remove();
        }

        $('.front__projecten__slider').slick({
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        });
    }

}

export default pageHome;
