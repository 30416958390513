import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const pageRotate = (container) => {


    let frontRotate = container.querySelector('.front__rotate');
    let frontRotateLeft = container.querySelector('.front__rotate__bg__left');
    let frontRotateRight = container.querySelector('.front__rotate__bg__right');

    const tlBg = gsap.timeline();
    tlBg.to(frontRotateLeft, {
        width: 0, scrollTrigger: {
            trigger: frontRotate,
            start: '0% 70%',
            end: '70% 70%',
            ease: 'power1.inOut',
            scrub: true,
            markers: false,
        },
    }, 0);

    tlBg.to(frontRotateRight, {
        width: 0, scrollTrigger: {
            trigger: frontRotate,
            start: '0% 70%',
            end: '70% 70%',
            ease: 'power1.inOut',
            scrub: true,
            markers: false,
        },
    }, 0);


    let rotateNumber0 = container.querySelector('.front__rotate__number0');
    let rotateNumber1 = container.querySelector('.front__rotate__number1');
    // let rotateNumber2 = container.querySelector('.front__rotate__number2');

    let rotateText0 = container.querySelector('.front__rotate__text0');
    let rotateText1 = container.querySelector('.front__rotate__text1');
    // let rotateText2 = container.querySelector('.front__rotate__text2');
    let rotateText3 = container.querySelector('.front__rotate__text3');
    let rotateText4 = container.querySelector('.front__rotate__text4');
    // let rotateText5 = container.querySelector('.front__rotate__text5');
    let rotateText6 = container.querySelector('.front__rotate__text6');

    let rotateNumber0Left = rotateNumber0.querySelector('.front__rotate__number__left')
    let rotateNumber0Right = rotateNumber0.querySelector('.front__rotate__number__right')

    let rotateNumber1Left = rotateNumber1.querySelector('.front__rotate__number__left')
    let rotateNumber1Right = rotateNumber1.querySelector('.front__rotate__number__right')

    // let rotateNumber2Left = rotateNumber2.querySelector('.front__rotate__number__left')
    // let rotateNumber2Right = rotateNumber2.querySelector('.front__rotate__number__right')

    let rotateNumber3Left = rotateText3.querySelector('.front__rotate__single__left')
    let rotateNumber3Right = rotateText3.querySelector('.front__rotate__single__right')

    let rotateNumber4Left = rotateText4.querySelector('.front__rotate__single__left')
    let rotateNumber4Right = rotateText4.querySelector('.front__rotate__single__right')

    // let rotateNumber5Left = rotateText5.querySelector('.front__rotate__single__left')
    // let rotateNumber5Right = rotateText5.querySelector('.front__rotate__single__right')

    let rotateNumber6Left = rotateText6.querySelector('.front__rotate__single__left')
    let rotateNumber6Right = rotateText6.querySelector('.front__rotate__single__right')


    function forwardAnimation() {
        const tlForward = gsap.timeline();

        let duration = 2;
        let timeBetweenAnimation = '<+=3';

        tlForward
            .fromTo(rotateNumber0Left, {y: '0'}, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0')
            .fromTo(rotateNumber0Right, {y: '0'}, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0.5')
            .fromTo(rotateText0, {y: '0'}, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0')

            .to(rotateNumber0Left, {duration: duration, ease: 'expo.out', y: '-100%'}, timeBetweenAnimation)
            .to(rotateNumber0Right, {duration: duration, ease: 'expo.out', y: '-100%'}, '<+=0.5')
            .to(rotateText0, {duration: duration, ease: 'expo.out', y: '-100%'}, '<')

            .to(rotateNumber1Left, {duration: duration, ease: 'expo.out', y: '0'}, '<-=0.5')
            .to(rotateNumber1Right, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0.5')
            .to(rotateText1, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0')

            .to(rotateNumber1Left, {duration: duration, ease: 'expo.out', y: '-100%'}, timeBetweenAnimation)
            .to(rotateNumber1Right, {duration: duration, ease: 'expo.out', y: '-100%'}, '<+=0.5')
            .to(rotateText1, {duration: duration, ease: 'expo.out', y: '-100%'}, '<+=0')

            // .to(rotateNumber2Left, { duration: duration, ease: 'expo.out', y: '0' }, '<-=0.5')
            // .to(rotateNumber2Right, {duration: duration, ease: 'expo.out',  y: '0' }, '<+=0.5')
            // .to(rotateText2, { duration: duration, ease: 'expo.out', y: '0' },'<+=0')

            // .to(rotateNumber2Left, { duration: duration, ease: 'expo.out', y: '-100%' }, timeBetweenAnimation)
            // .to(rotateNumber2Right, {duration: duration, ease: 'expo.out',  y: '-100%' }, '<+=0.5')
            // .to(rotateText2, { duration: duration, ease: 'expo.out', y: '-100%' },'<+=0')

            .to(rotateNumber3Left, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0')
            .to(rotateNumber3Right, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0.5')

            .to(rotateNumber3Left, {duration: duration, ease: 'expo.out', y: '-100%'}, timeBetweenAnimation)
            .to(rotateNumber3Right, {duration: duration, ease: 'expo.out', y: '-100%'}, '<+=0.5')

            .to(rotateNumber4Left, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0')
            .to(rotateNumber4Right, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0.5')

            .to(rotateNumber4Left, {duration: duration, ease: 'expo.out', y: '-100%'}, timeBetweenAnimation)
            .to(rotateNumber4Right, {duration: duration, ease: 'expo.out', y: '-100%'}, '<+=0.5')

            // .to(rotateNumber5Left, { duration: duration, ease: 'expo.out', y: '0' },'<+=0')
            // .to(rotateNumber5Right, {duration: duration, ease: 'expo.out',  y: '0' }, '<+=0.5')

            // .to(rotateNumber5Left, { duration: duration, ease: 'expo.out', y: '-100%' }, timeBetweenAnimation)
            // .to(rotateNumber5Right, {duration: duration, ease: 'expo.out',  y: '-100%' }, '<+=0.5')

            .to(rotateNumber6Left, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0')
            .to(rotateNumber6Right, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0.5')

            .to(rotateNumber6Left, {duration: duration, ease: 'expo.out', y: '-100%'}, timeBetweenAnimation)
            .to(rotateNumber6Right, {duration: duration, ease: 'expo.out', y: '-100%'}, '<+=0.5')

            .fromTo(rotateNumber0Left, {y: '100%'}, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0')
            .fromTo(rotateNumber0Right, {y: '100%'}, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0.5')
            .fromTo(rotateText0, {y: '100%'}, {duration: duration, ease: 'expo.out', y: '0'}, '<+=0')


        return tlForward;
    }

// function backwardAnimation()
// {
//     const tlBackward = gsap.timeline();
//
//     tlBackward
//         .to(rotateNumber1Left, { duration: 2, ease: 'expo.out', y: '100%' }, 0)
//         .to(rotateNumber1Right, { duration: 2, ease: 'expo.out', y: '100%' }, 0.5)
//         .to(rotateText1, { duration: 2, ease: 'expo.out', y: '100%' },0.5)
//         .to(rotateNumber0Left, { duration: 2, ease: 'expo.out', y: '0' },0)
//         .to(rotateNumber0Right, { duration: 2, ease: 'expo.out', y: '0' } ,0.5)
//         .to(rotateText0, { duration: 2, ease: 'expo.out', y: '0' },0.5);
//
//     return tlBackward;
// }

    const master = gsap.timeline();
    master.add(forwardAnimation())

    master.eventCallback('onComplete', function () {
        master.restart()
    })

    const frontRotateButton = document.querySelector('.js-pause-front-rotate');
    const frontRotateButtonIcons = document.querySelectorAll('.front__rotate__button__icon');

    frontRotateButton.addEventListener('click', (e) => {
        const button = e.target
        if (master.isActive()) {
            master.pause();
            button.classList.add('is-paused')
            frontRotateButtonIcons[0].style.display = 'block';
            frontRotateButtonIcons[1].style.display = 'none';
        } else {
            master.play();
            button.classList.remove('is-paused')
            frontRotateButtonIcons[0].style.display = 'none';
            frontRotateButtonIcons[1].style.display = 'block';
        }
    });


}

export default pageRotate;

