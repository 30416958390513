import barba from '@barba/core';

// Page
import defaultJs from './util/default';
import menu from './util/menu'
import pageLeave from './pageTransitions/pageLeave';
import pageEnter from './pageTransitions/pageEnter';
import pageDefault from './pageAnimations/pageDefault';
import pageHome from './pageAnimations/pageHome';
import pageLayerBanner from './pageAnimations/pageLayerBanner';
import pageService from './pageAnimations/pageService';
import pageRotate from './pageAnimations/pageRotate';
import pagePosts from './routes/pagePosts';
import pageCialdini from './routes/pageCialdini';

export default {
    init() {
        barba.init({
            debug: false,
            // define a custom function that will prevent Barba
            // from working on links that contains a `prevent` CSS class
            prevent: ({ el }) => el.classList && el.classList.contains('prevent'),

            transitions: [
                {
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container).eventCallback('onComplete', function () {
                            pageDefault(next.container);
                        });
                        pageLayerBanner(next.container);
                        pageService(next.container);
                        pageCialdini(next.container);
                    },
                    after(data) {
                        defaultJs();
                        menu(data);
                    },
                    once(data) {
                        pageEnter(data.next.container).eventCallback('onComplete', function () {
                            pageDefault(data.next.container);
                        });
                        pageLayerBanner(data.next.container);
                        pageService(data.next.container);
                        pageCialdini(data.next.container);
                        menu(data);
                    },
            },
                {
                    name: 'home',
                    to: {
                        namespace: ['home'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container).eventCallback('onComplete', function () {
                            pageDefault(next.container);
                        });
                        pageHome(next.container);
                        pageLayerBanner(next.container);
                        pageRotate(next.container);
                    },
                    after(data) {
                        defaultJs();
                        menu(data);
                    },
                    once(data) {
                        pageEnter(data.next.container).eventCallback('onComplete', function () {
                            pageDefault(data.next.container);
                        });
                        pageHome(data.next.container);
                        pageLayerBanner(data.next.container);
                        pageRotate(data.next.container);
                        menu(data);
                    },
            },{
                name: 'posts',
                to: {
                    namespace: ['posts'],
                },
                leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container).eventCallback('onComplete', function () {
                            pageDefault(next.container);
                        });
                        pagePosts(next.container);
                },
                    after(data) {
                        // Reset body class
                        defaultJs();
                        menu(data);
                },
                    once(data) {
                        pageEnter(data.next.container).eventCallback('onComplete', function () {
                            pageDefault(data.next.container);
                        });
                        pagePosts(data.next.container);
                        menu(data);
                },
            },
                ],
        });
    },
}
